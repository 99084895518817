import React, { useContext, useEffect, useMemo } from "react"
import { Context } from "./context"
import scrollToTop from "./helpers/scrollToTop"
import PaymentPosition from "./PaymentPosition"
import { SuccessPropsType } from "./types/main"


const Success: React.FC<SuccessPropsType> = ({ successData }) => {

    useEffect(() => {
        scrollToTop()
    }, [])
    
    const context = useContext(Context)

    const address = useMemo(() => {
        if (successData?.other) {
            let addressString = ""
            addressString += successData.other.delivery_house || ""
            addressString += successData.other.delivery_entrance ? addressString ?
             `, под. ${successData.other.delivery_entrance}` : `под. ${successData.other.delivery_entrance}` : ""
            addressString += successData.other.delivery_floor ? addressString ?
             `, эт. ${successData.other.delivery_floor}` : `эт. ${successData.other.delivery_floor}` : ""
             addressString += successData.other.delivery_apartment ? addressString ?
             `, кв. ${successData.other.delivery_apartment}` : `кв. ${successData.other.delivery_apartment}` : ""
             return addressString
        }
        return ""
    }, [successData])

    return <div className="success__container">
        <h2 className="success__title">Большое спасибо за заказ!</h2>
        <div className="form__section">
            <h4 className="form__section-title">Детали заказа</h4>
            {successData?.cart.map(product => <PaymentPosition
                key={`${product.id}__${product.modifiers?.length}__${product.price}`}
                addInCart={() => { }}
                counterButtons={false}
                {...product}
            />)}
            {address ? <span className="success__prop">{`Адрес доставки: ${address}`}</span> : null}
            {successData?.other?.count_devices ?
             <span className="success__prop">{`Количество столовых приборов: ${successData?.other?.count_devices}`}</span> : null}
            {successData?.price ?
             <span className="success__prop">{`Итого к оплате: ${successData?.price} ₽`}</span> : null}
             {successData?.other?.comment ?
             <span className="success__prop">{`Комментарий к заказу: ${successData?.other?.comment}`}</span> : null}
        </div>
        <div className="form__section">
            Пожалуйста, ожидайте звонка. В случае, если звонок не поступит в течение 10 минут, просим вас перезвонить на номер 
             <a href={`tel:${context?.settings?.company_phone?.replace(/[^\d]/gi, "")}`}>{` ${context?.settings?.company_phone}`}</a>
        </div>
    </div>
}

export default Success