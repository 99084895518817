import axios from "axios";
import qs from "qs"


const searchParams = qs.parse(window.location.search)

const subDomain = searchParams["company"] || searchParams["?company"]

const instanse = axios.create({
    baseURL: `https://${subDomain || "mrbao"}.eatobox-api.ru`,
    headers: {
        "Content-Type": "application/json"
    }
})

export default async (application: string, command: string, data: any = undefined) => {
    const response = await instanse.post(`/q=${application}__${command}`, {
        application,
        command,
        data
    })
    return response.data
}
