import { Form, Formik, useFormikContext } from "formik"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import * as yup from 'yup';
import Checkbox from "./components/Checkbox";
import { FoodCardFormComponentPropsType, FoodCardPropsType, FormModifierType, ModifierCategoryType } from "./types/main";


const FormComponent: React.FC<FoodCardFormComponentPropsType> = ({ modifierCategories, getSectionClass, setShow }) => {
    const formProps: any = useFormikContext()

    const isEmpty = useMemo(() => {
        const valuesAsArray: Array<Array<FormModifierType>> = Object.values(formProps.values.modifiers)
        return valuesAsArray.every((categoryValues) => !categoryValues?.length)
    }, [formProps.values])

    const modifiersTotalPrice = useMemo(() => {
        const valuesAsArray: Array<Array<FormModifierType>> = Object.values(formProps.values.modifiers)
        return valuesAsArray.reduce((currentTotalValue, categoryValues) => currentTotalValue + categoryValues
            .reduce((currentValue, nextValue) => currentValue + Number(nextValue.price), 0), 0) ?? 0
    }, [formProps.values])

    const isDisabled = useCallback((id: string, modifiers: any, category: ModifierCategoryType) => {
        return (modifiers?.[`category__${category.id}`]?.length === Number(category.max_amount)) &&
            !(modifiers?.[`category__${category.id}`]?.some((item: FormModifierType) => item.id === id))
    }, [])

    return <Form onSubmit={formProps.handleSubmit}>
        <div className="form__container">
            {modifierCategories.map(category => <div className={getSectionClass(category, formProps)}>
                <h4 className="form__section-title">{category.title}</h4>
                {category.modifiers.map(modifier => (
                    <div className="form__control row">
                        <Checkbox
                            modifier={modifier}
                            category={category}
                            formProps={formProps}
                            isDisabled={isDisabled}
                        />
                    </div>
                )
                )}
            </div>)}
        </div>
        <div className="modal__btn-container">
            <button type="button" className="product__modifier-btn product__modifier-back" onClick={e => setShow(false)}>Назад</button>
            <button className="product__modifier-btn" type="submit">{isEmpty ? "Пропустить" :
                <>Добавить к блюду <span className="form__price">{`+${modifiersTotalPrice} ₽`}</span></>}
            </button>
        </div>
    </Form>
}

const FoodCard: React.FC<FoodCardPropsType> = ({ addInCart, currentProductInCart, ...props }) => {
    const [show, setShow] = useState(false)
    const [showDetailInfo, setShowDetailInfo] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const formSchemaAndInits = useMemo(() => {
        const required = {}
        const inits = {}
        props.modifierCategories.forEach(category => {
            if (Number(category.min_amount)) {
                //@ts-ignore
                required[`category__${category.id}`] = yup.array().min(Number(category.min_amount)).required()
                //@ts-ignore
                inits[`category__${category.id}`] = []
            }
        })
        return {
            schema: yup.object().shape({
                modifiers: yup.object(required)
            }),
            initialValues: {
                modifiers: inits
            }
        }
    }, [props.modifierCategories])

    const getSectionClass = useCallback((category: ModifierCategoryType, formikProps: any) => {
        return (formikProps.errors?.modifiers?.[`category__${category.id}`] &&
            formikProps.touched?.modifiers?.[`category__${category.id}`]) ? "form__section invalid" : "form__section"
    }, [])

    useEffect(() => {
        show || showDetailInfo ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset'
    }, [show, showDetailInfo]);
    return <>
        <li className={`product__card${currentProductInCart.length ? " selected" : ""}${showDetailInfo ? " details" : ""}`} onClick={e => {
            const element = e.target
            //@ts-ignore
            const exceptions = element.closest("div").classList.contains("product__btns-container") || element.closest("div").classList.contains("product__props")
            return !exceptions && !showDetailInfo ? setShowDetailInfo(true) : null
        }}>
            <img className="product__image"
                src={props.image ?? "https://w7.pngwing.com/pngs/156/887/png-transparent-local-food-ottawa-computer-icons-restaurant-others-miscellaneous-food-company.png"}
                alt={props.title ?? "food"}
                onError={e => {
                    //@ts-ignore
                    e.target.errors = null
                    //@ts-ignore
                    e.target.src = "https://w7.pngwing.com/pngs/156/887/png-transparent-local-food-ottawa-computer-icons-restaurant-others-miscellaneous-food-company.png"
                }} />
            <h5 className="product__title">{props.title} {Number(props.weight) ? <span className="product__weight">{`${props.weight}\xA0г.`}</span> : null} </h5>
            {showDetailInfo ? <>
                <div className="product__description">
                    {props.description}
                </div>
                <div className="product__pfc">
                    <div>
                    {props.energy_amount ?? "-"}
                    <span>ккал</span>
                    </div>
                    <div>
                    {props.proteins_amount ?? "-"}
                    <span>белки</span>
                    </div>
                    <div>
                    {props.fat_amount ?? "-"}
                    <span>жиры</span>
                    </div>
                    <div>
                    {props.carbohydrates_amount ?? "-"}
                    <span>углеводы</span>
                    </div>
                </div>
            </> : null}

            <div className="product__props">
                {currentProductInCart.length ?
                    <div className="product__btns-container">
                        <button className="product__counter-btn" onClick={() => {
                            if (props.modifierCategories.length) {
                                setIsDelete(true)
                                return setShow(true)
                            } else {
                                addInCart(props, "delete")
                            }
                        }}>
                            -
                        </button>
                        {currentProductInCart.length ? <span className="product__counter">
                            {currentProductInCart.reduce((currentValue, nextValue) => currentValue + nextValue.count, 0) ?? 0}
                        </span> : null}
                        <button className="product__counter-btn" onClick={() => {
                            if (props.modifierCategories?.length) {
                                return setShow(true)
                            } else {
                                addInCart(props, "add")
                            }
                        }}>
                            +
                        </button>
                    </div> : <button className="product__btn" onClick={() => {
                        if (props.modifierCategories?.length) {
                            return setShow(true)
                        } else {
                            addInCart(props, "add")
                        }
                    }}>
                        {props.price ? `${props.price} ₽` : "Добавить"}
                    </button>}
                    {showDetailInfo ? <button className="product__btn" style={{marginTop: "10px"}} type="button" onClick={() => setShowDetailInfo(false)}>
                Закрыть
            </button> : null}
            </div>
        </li>
        {show ? <div className="product__modal">
            <div className="product__modal-container">
                {!isDelete ? <Formik onSubmit={val => {
                    const modifiers = Object.values(val.modifiers).reduce((currentValue: Array<any>, nextValue) => currentValue.concat(nextValue), [])
                    addInCart({
                        ...props,
                        modifiers,
                        price: String(Number(props.price) + (modifiers.reduce((currentValue, nextValue) => currentValue +
                            Number(nextValue.price), 0) ?? 0))
                    })
                    return setShow(false)
                }} initialValues={formSchemaAndInits.initialValues} validationSchema={formSchemaAndInits.schema}>
                    <FormComponent modifierCategories={props.modifierCategories} getSectionClass={getSectionClass} setShow={setShow} />
                </Formik> :
                    <div className="form__section">
                        {currentProductInCart.map(product => (<div className="payment__position">
                            <div className="payment__position-description">
                                <div className="payment__position-title">{product.title}</div>
                                <span className="payment__position-count">{` x${product.count}`}</span>
                            </div>
                            {product.modifiers ? <div className="payment__position-modifiers">{product.modifiers.reduce((currentValue, nextValue, index, array) => currentValue + `${nextValue.title}${(index === array.length - 1) ? "" : " / "}`, "")}</div> : null}
                            <div className="payment__position-props">
                                <div className="payment__position-btns">
                                    <button type="button" className="payment__position-btn" onClick={() => addInCart(product, "delete")}>
                                        -
                                    </button>
                                </div>
                                <span className="payment__position-price">
                                    {`${product.count * Number(product.price)} ₽`}
                                </span>
                            </div>
                        </div>))}
                        <div className="modal__btn-container" style={{ justifyContent: "center" }}>
                            <button className="product__modifier-btn" style={{ width: "100%" }} type="button" onClick={e => {
                                setIsDelete(false)
                                return setShow(false)
                            }}>Подтвердить</button>
                        </div>
                    </div>}
            </div>
        </div> : null}
    </>
}

export default FoodCard