import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
//@ts-ignore
import FoodCategory from "./FoodCategory";
import scrollToTop from "./helpers/scrollToTop";
import moment from 'moment';
import { FoodListPropsType } from "./types/main";
import { useContext } from "./context";
import Undelivery from "./components/Undelivery";
import SplashScreen from "./components/SplashScreen";


const FoodList: React.FC<FoodListPropsType> = ({ list, productCategories, cart, addInCart, setUserPhone }) => {
    const context = useContext()
    const navigate = useNavigate()
    const location = useLocation()
    const [currentCategory, setCurrentCategory] = useState<number | null>(null)

    const totalPrice = useMemo(() => {
        //сумма позиций в корзине
        const cartPrice = cart.length ? cart.reduce((current, next) => current + (next.count * Number(next.price)), 0) : 0
        //сумма доставки
        const deliveryCost = context?.settings?.delivery_price
        //порог суммы позиций для бесплатной доставки
        const thresholdPrice = context?.settings?.order_price_for_free_delivery
        return (deliveryCost && thresholdPrice && (cartPrice < Number(thresholdPrice))) ?
            cartPrice + Number(deliveryCost)
            : cartPrice
    }, [cart, context])

    useEffect(() => {
        if (location.search) {
            const userPhone = location.search.replace(/[^\d]/gi, '')
            setUserPhone(userPhone)
            localStorage.setItem("client_phone", userPhone)
        }
    }, [])

    useEffect(() => {
        if (currentCategory) {
            const menuList = document.querySelector(".product__list-menu")
            const currentCategoryElement = document.getElementById(`product__list-menu-btn-${currentCategory}`)
            if (currentCategoryElement && menuList) {
                const offsetLeft = currentCategoryElement.offsetLeft
                menuList.scrollTo({
                    left: offsetLeft - 10,
                    behavior: "smooth"
                })
            }
        }
    }, [currentCategory])

    const goToCategory = useCallback((id: number) => {
        const currentCategoryElement = document.getElementById(`category-${id}`)
        if (currentCategoryElement) {
            const offsetTop = currentCategoryElement.offsetTop
            window.scrollTo({
                top: offsetTop - 60, //60px - высота меню
                behavior: "smooth"
            })
        }
    }, [])


    if (!list.length || !productCategories.length) {
        return <SplashScreen />
    }

    return <div>
        <ul className="product__list-menu">
            {productCategories.map(category => (
                <button
                    type="button"
                    id={`product__list-menu-btn-${category.id}`}
                    className={`product__list-menu-btn ${currentCategory === category.id ? "product__list-menu-btn--active" : ""}`}
                    onClick={() => goToCategory(category.id)}>{category.title}</button>
            ))}
        </ul>
        <ul className="product__list">
            {productCategories.map(category => (
                <FoodCategory
                    key={category.id}
                    category={category}
                    list={list}
                    addInCart={addInCart}
                    cart={cart}
                    setCurrentCategory={setCurrentCategory}
                />
            ))}
        </ul>
        <div className={`payment__navigate ${!cart.length ? "hidden" : ""}`}>
            <Undelivery />
            <button
                className="payment__navigate-btn"
                onClick={() => {
                    navigate("/payment")
                    scrollToTop()
                }}
                disabled={context?.isDeliveryTime === false}
            >Корзина <span className="payment__navigate-price">{`${totalPrice} ₽`}</span></button>
        </div>
        <div className="copyright">
            &copy; <a href="https://oxbox.ru">{` Eatobox `}</a> {moment().format("YYYY")} г.
        </div>
    </div>
}

export default FoodList