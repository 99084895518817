import React from "react";


const SplashScreen: React.FC = () => {
    return <div id="splash-screen" className="kt-splash-screen">
        <svg className="splash-spinner" viewBox="0 0 50 50">
            <circle className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke-width="5"
            ></circle>
        </svg>
    </div>
}

export default SplashScreen