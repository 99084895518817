import React, { useCallback, useMemo, useRef } from "react"
import { CheckboxType, FormModifierType } from "../types/main"


const Checkbox: React.FC<CheckboxType> = ({ modifier, category, formProps, isDisabled }) => {

    const ref = useRef<HTMLInputElement>(null)
    const clickHandler = useCallback(() => {
        if (ref.current) {
            ref.current.click()
        }
    }, [ref])

    const getClass = () => {
        let baseClass = "product__modifier"
        if (ref.current) {
            if (ref.current.checked) {
                baseClass += " checked"
            } else if (ref.current.disabled) {
                baseClass += " disabled"
            }
        }
        return baseClass
    }

    return <>
        <input
            id={`modifier__${modifier.id}`}
            className="product__modifier"
            type="checkbox"
            checked={formProps.values?.modifiers?.[`category__${category.id}`]?.some((item: FormModifierType) => item.id === modifier.id)}
            onChange={(e) => {
                const modifiersValueClone = Array.isArray(formProps.values.modifiers[`category__${category.id}`]) ?
                    [...formProps.values.modifiers[`category__${category.id}`]] : []
                if (modifiersValueClone.some(item => item.id === modifier.id)) {
                    const modifierItem = modifiersValueClone.find(item => item.id === modifier.id)
                    const modifierIndex = modifiersValueClone.indexOf(modifierItem)
                    modifiersValueClone.splice(modifierIndex, 1)
                } else {
                    modifiersValueClone.push({ id: modifier.id, title: modifier.title, price: modifier.price, category_id: category.id })
                }
                formProps.setFieldValue(`modifiers`, { ...formProps.values.modifiers, [`category__${category.id}`]: modifiersValueClone })
            }}
            disabled={isDisabled(modifier.id, formProps.values.modifiers, category)}
            ref={ref}
        />
        <span className={getClass()} onClick={clickHandler}></span>
        <label className="product__modifier-title" htmlFor={`modifier__${modifier.id}`}>{modifier.title}{Number(modifier.price) ?
            <span>{` +${modifier.price} ₽`}</span> : null}</label>
    </>
}

export default Checkbox