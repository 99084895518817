import React from "react"
import { PaymentPositionPropsType } from "./types/main"




const PaymentPosition: React.FC<PaymentPositionPropsType> = ({counterButtons = true, ...props}) => {
    return <div className="payment__position">
        <div className="payment__position-description">
            <img className="payment__position-image" src={props.image} alt={props.title ?? ""} />
            <div className="payment__position-title">{props.title}</div>
            <span className="payment__position-count">{` x${props.count}`}</span>
        </div>
        {props.modifiers ? <div className="payment__position-modifiers">{props.modifiers.reduce((currentValue, nextValue, index, array) => currentValue + `${nextValue.title}${(index === array.length - 1) ? "" : " / "}`, "")}</div> : null}
        <div className="payment__position-props">
            {
            counterButtons ? <div className="payment__position-btns">
                <button type="button" className="payment__position-btn" onClick={() => props.addInCart(props, "delete")}>
                    -
                </button>
                <button type="button" className="payment__position-btn" onClick={() => props.addInCart(props, "add")}>
                    +
                </button>
            </div> : null
            }
            <span className="payment__position-price" style={counterButtons ? {} : {width: "100%"}}>
                {`${props.count * Number(props.price)} ₽`}
            </span>
        </div>
    </div>
}

export default PaymentPosition