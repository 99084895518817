import { Form, Formik, useFormikContext } from "formik"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import PaymentPosition from "./PaymentPosition"
import * as yup from 'yup';
import api from "./api";
import ReactSelect from "react-select";
import MaskedInput from 'react-text-mask'
import scrollToTop from "./helpers/scrollToTop";
import { CartProductType, PaymentFormComponentPropsType, PaymentPropsType } from "./types/main";
import { useContext } from "./context";
import Undelivery from "./components/Undelivery";



const FormComponent: React.FC<PaymentFormComponentPropsType> = (
    { cart, currentUser, getClass,
        addInCart, navigate, deliveryOptions,
        paymentOptions, maxBonuses }) => {

    const context = useContext()
    //сумма доставки
    const deliveryCost = context?.settings?.delivery_price
    //порог суммы позиций для бесплатной доставки
    const thresholdPrice = context?.settings?.order_price_for_free_delivery
    //условие для отображения бонусной программы: работа через iiko и наличие у клиента бонусов
    const showBonuses = useMemo(() => {
        return context?.settings?.is_iiko && typeof context.userBonuses === "number"
    }, [context])
    const phoneNumberMask = [
        "+",
        /\d/,
        " ",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/
    ];

    const formProps: any = useFormikContext()

    const { values, setFieldValue, handleSubmit } = formProps
    useEffect(() => {
        const cartPrice = cart.reduce((current, next) => current + (next.count * Number(next.price)), 0)
        //Проверяем, чтобы сумма корзины не была равна 0 (чтобы не отображалась только доставка при удалении всех позиций), наличие стоимости доставки и т.д.
        const totalPrice = (cartPrice !== 0 && deliveryCost && thresholdPrice && (cartPrice < Number(thresholdPrice)))
            ? cartPrice + Number(deliveryCost) : cartPrice
        setFieldValue("price", cartPrice)
        setFieldValue("products", cart)
    }, [cart, context])

    useEffect(() => {
        if (currentUser) {
            setFieldValue("client_phone", currentUser.phone)
            setFieldValue("client_id", currentUser.id)
        }
    }, [currentUser])

    return (
        <Form className="form__payment" onSubmit={handleSubmit}>

            <div className="form__section">
                <div className="form__control">
                    <label className="form__label" htmlFor="phone__input">Ваш телефон</label>
                    <MaskedInput
                        value={values.client_phone}
                        mask={phoneNumberMask}
                        id="phone__input"
                        className={`form__input ${getClass("client_phone", formProps)}`}
                        name="client_phone"
                        onChange={(e) => {
                            const value = e.target.value?.replace(/\s+|\-|\_|\(|\)|\+/gi, "")
                            setFieldValue("client_phone", value)
                        }}
                        disabled
                    />
                </div>
                {showBonuses ? <div className="form__control">
                    <label className="form__label" htmlFor="bonuses-info__input">Ваши бонусы</label>
                    <input
                        id="bonuses-info__input"
                        className="form__input"
                        type="number"
                        name=""
                        value={Number(context?.userBonuses)}
                        disabled
                    />
                </div> : null}
            </div>
            <div className="form__section">
                <div className="form__control">
                    <label className="form__label" htmlFor="house__input">Адрес доставки</label>
                    <input
                        id="house__input"
                        className="form__input"
                        type="text"
                        name="delivery_house"
                        value={values.delivery_house}
                        onChange={(e) => {
                            setFieldValue("delivery_house", e.target.value)
                        }}
                    />
                </div>
                <div className="form__address-props">
                    <div className="form__control--third">
                        <label className="form__label" htmlFor="entrance__input">Подъезд</label>
                        <input
                            id="entrance__input"
                            className="form__input"
                            type="number"
                            name="delivery_entrance"
                            value={values.delivery_entrance}
                            onChange={(e) => {
                                setFieldValue("delivery_entrance", e.target.value)
                            }}
                        />
                    </div>
                    <div className="form__control--third">
                        <label className="form__label" htmlFor="floor__input">Этаж</label>
                        <input
                            id="floor__input"
                            className="form__input"
                            type="number"
                            name="delivery_floor"
                            value={values.delivery_floor}
                            onChange={(e) => {
                                setFieldValue("delivery_floor", e.target.value)
                            }}
                        />
                    </div>
                    <div className="form__control--third">
                        <label className="form__label" htmlFor="apartment__input">Квартира</label>
                        <input
                            id="apartment__input"
                            className="form__input"
                            type="number"
                            name="delivery_apartment"
                            value={values.delivery_apartment}
                            onChange={(e) => {
                                setFieldValue("delivery_apartment", e.target.value)
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="form__section">
                <label className="form__label">Детали заказа</label>
                {cart.map(product => <PaymentPosition
                    key={`${product.id}__${product.modifiers?.length}__${product.price}`}
                    addInCart={addInCart}
                    {...product}
                />)}
            </div>
            {/* Если есть доставка */}
            {deliveryCost && Number(deliveryCost) !== 0 && values?.price !== 0 ?
                <div className="form__section">
                    <div className="payment__details">{`Стоимость доставки: ${values?.price < Number(thresholdPrice) ? `${deliveryCost} ₽` : "Бесплатно!"}`}</div>
                    {values?.price < Number(thresholdPrice) ?
                        <div className="payment__details">{`До бесплатной доставки: ${Number(thresholdPrice) - values?.price} ₽`}</div> : null}
                </div>
                : null}
            {/* Если есть бонусная программа */}
            {showBonuses ?
                <div className="form__section">
                    <div className="form__control">
                        <label className="form__label" htmlFor="bonuses__input">{`Списать бонусы (макс.: ${maxBonuses})`}</label>
                        <input
                            id="bonuses__input"
                            className={`form__input ${getClass("bonus_count", formProps)}`}
                            type="number"
                            name="bonus_count"
                            min={0}
                            step={0.01}
                            value={values.bonus_count}
                            onChange={(e) => {
                                setFieldValue("bonus_count", Number(e.target.value) < 0 ? 0 : e.target.value)
                            }}
                        />
                    </div>
                </div> : null}
            <div className="form__section">
                <div className="form__control">
                    <label className="form__label" >Столовые приборы</label>
                    <input type="number" id="cutlery__count-input" name="count_devices" value={values.count_devices} />
                    <div className="cutlery__count-btns">
                        <button
                            type="button"
                            className="cutlery__count-btn"
                            onClick={() => {
                                setFieldValue("count_devices", --values.count_devices)
                            }}
                            disabled={!values.count_devices}
                        >
                            -
                        </button>
                        <span className="cutlery__count">
                            {values.count_devices ?? 0}
                        </span>
                        <button
                            type="button"
                            className="cutlery__count-btn"
                            onClick={() => {
                                setFieldValue("count_devices", ++values.count_devices)
                            }}>
                            +
                        </button>
                    </div>
                </div>
            </div>
            <div className="form__section">
                <div className="form__control">
                    <label className="form__label" htmlFor="comments__textarea">Комментарии к заказу</label>
                    <textarea
                        id="comments__textarea"
                        className="form__textarea"
                        name="comment"
                        value={values.comment}
                        onChange={(e) => {
                            setFieldValue("comment", e.target.value)
                        }}
                    />
                </div>
            </div>
            <div className="form__section">
                {deliveryOptions ? <div className="form__control">
                    <label className="form__label">Способ доставки</label>
                    <ReactSelect
                        classNamePrefix="custom-select"
                        className={getClass("deliveryType_id", formProps)}
                        options={deliveryOptions}
                        onChange={e => setFieldValue("deliveryType_id", e?.value)}
                        placeholder="Выберите..."
                        menuPlacement="top" />
                </div> : null}
                {paymentOptions ? <div className="form__control">
                    <label className="form__label">Способ оплаты</label>
                    <ReactSelect
                        classNamePrefix="custom-select"
                        className={getClass("iiko_payment_id", formProps)}
                        options={paymentOptions}
                        onChange={e => setFieldValue("iiko_payment_id", e?.value)}
                        placeholder="Выберите..."
                        menuPlacement="top" />
                </div> : null}
            </div>
            <Undelivery />
            <div className="form__btns-container">
                <button
                    type="button"
                    className="form__back-btn"
                    onClick={e => {
                        navigate("/")
                        scrollToTop()
                    }}>Назад</button>
                <button
                    type="submit"
                    className="form__submit"
                    disabled={!cart.length || context?.isDeliveryTime === false}>Подтвердить заказ <span className="form__price">
                        {`${(values.price !== 0 && deliveryCost && thresholdPrice && (values.price < Number(thresholdPrice)))
                            ? values.price + Number(deliveryCost) : values.price} ₽`}
                    </span>
                </button>
            </div>
        </Form>
    )
}

const Payment: React.FC<PaymentPropsType> = ({ cart, setCart, addInCart, currentUser, setSuccessData }) => {
    const navigate = useNavigate()
    /* Проверяем наличие товаров в корзине через storage (при каждом изменении корзины происходит перезапись).
    По корзине в состоянии не совсем корректно получается проверить, т.к. монтаж значений происходит с задержкой.
    Если нет товаров, перенаправлять на страницу со списком*/
    const context = useContext()
    //для формы валидации вычисляем максимальное значение бонусов
    //если бонусы клиента больше по кол-ву максимального значения бонусов для списания, то потолком является установленное значение, иначе значение бонусов клиента
    //если установленного потолка нет, то считаем, что можно списывать все бонусы
    //при отсутствии бонусов приходит false с сервера
    const maxBonuses = useMemo(() => {
        if (context?.settings?.is_iiko) {
            const systemMaxBonuses = Number(context.settings.max_bonuses)
            return (systemMaxBonuses && (systemMaxBonuses < context.userBonuses)) ? systemMaxBonuses : typeof context.userBonuses !== "boolean" ? context.userBonuses : 0
        } else {
            return 0
        }
    }, [context])

    useEffect(() => {
        (async () => {
            const storageCart = localStorage.getItem("cart")
            const isNotEmpty = Boolean(storageCart && JSON.parse(storageCart).length)
            if (!isNotEmpty) {
                navigate("/")
            }
            const paymentsResponse = await api("iiko_payments", "get")
            setPaymentMethods(paymentsResponse.data)
            const deliveryResponse = await api("deliveryTypes", "get")
            setDeliveryTypes(deliveryResponse.data)
        })()
    }, [])

    const [paymentMethods, setPaymentMethods] = useState<Array<{
        id: number,
        title: string
    }>>([])
    const [deliveryTypes, setDeliveryTypes] = useState<Array<{
        id: number,
        title: string
    }>>([])
    const [status, setStatus] = useState<string | null>(null)
    const [showStatus, setShowStatus] = useState(false)

    const appendOrder = useCallback((status: number, data: any) => {
        if (status === 200) {
            setSuccessData(data)
            //зачищаем корзину при успешном заказе
            setCart([])
            localStorage.setItem("cart", JSON.stringify([]))
            return navigate("/success")
        }
        setStatus("error")
        setShowStatus(true)
        return setTimeout(() => {
            setShowStatus(false)
            setStatus(null)
        }, 5000)
    }, [setSuccessData])

    useEffect(() => {
        showStatus ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset'
    }, [showStatus])

    const paymentOptions = useMemo(() => {
        return paymentMethods ? paymentMethods.map(method => ({ label: method.title, value: method.id })) : null
    }, [paymentMethods])

    const deliveryOptions = useMemo(() => {
        return deliveryTypes ? deliveryTypes.map(type => ({ label: type.title, value: type.id })) : null
    }, [deliveryTypes])

    const schema = yup.object().shape({
        client_phone: yup.string().required(),
        deliveryType_id: yup.number().required(),
        iiko_payment_id: yup.number().required(),
        bonus_count: yup.number().max(maxBonuses)
    });

    const getClass = useCallback((name: string, formikProps: any) => {
        const { errors, touched } = formikProps
        return (errors[name] && touched[name]) ? "invalid" : ""
    }, [])

    const initis: {
        client_phone: string,
        client_id: number
        deliveryType_id: number | null,
        iiko_payment_id: number | null,
        comment: string,
        count_devices: number,
        products: Array<CartProductType>,
        price?: number
    } = useMemo(() => {
        return Object.assign(
            {
                client_phone: "",
                deliveryType_id: null,
                iiko_payment_id: null,
                comment: "",
                count_devices: 0,
                products: cart
            }, currentUser ? {
                client_phone: currentUser.phone,
                client_id: currentUser.id
            } : {})
    }, [currentUser])


    return <div>
        <Formik
            initialValues={initis}
            validationSchema={schema}
            onSubmit={async values => {
                const price = values.price
                const request = {
                    ...values,
                    products: values.products
                        .map(product => ({ id: product.id, count: product.count, modifiers: product.modifiers?.map(modifier => ({ id: modifier.id, count: 1 })) }))
                }
                delete request.price
                const { status } = await api("orders", "add", request)
                appendOrder(status, { cart, price, other: request })
            }}>
            <FormComponent
                cart={cart}
                currentUser={currentUser}
                addInCart={addInCart}
                deliveryOptions={deliveryOptions}
                paymentOptions={paymentOptions}
                getClass={getClass}
                navigate={navigate}
                maxBonuses={maxBonuses}
            />
        </Formik>
        {
            showStatus ? <div className="status__modal">
                <div className="status__modal-container">
                    <div className="form__section">
                        <h4 className="status__modal-title">
                            {status ? status === "error" ? "Произошла ошибка." : "Ваш заказ успешно отправлен!" : null}
                        </h4>
                        <p className="status__modal-text">
                            {status ? status === "error" ? "Пожалуйста, попробуйте позже." : "Пожалуйста, ожидайте звонка." : null}
                        </p>
                    </div>
                </div>
            </div> : null
        }
    </div>
}

export default Payment