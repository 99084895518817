import moment from 'moment';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import api from './api';
import './App.css';
import { Context } from './context';
//исправить
//@ts-ignore
import FoodList from './FoodList.tsx';
import Payment from './Payment';
import Success from './Success';
import { CartProductType, ProductCategoryType, ProductType, SettingsType, SuccessDataType, UserType } from './types/main';




const App: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<UserType | null>(null)
  const [userPhone, setUserPhone] = useState<string | null>(null)
  const [userBonuses, setUserBonuses] = useState<boolean | number>(false)
  const [list, setList] = useState<Array<ProductType>>([])
  const [cart, setCart] = useState<Array<CartProductType>>([])
  const [successData, setSuccessData] = useState<SuccessDataType | null>(null)
  const [settings, setSettings] = useState<SettingsType | null>(null)
  const [productCategories, setProductCategories] = useState<Array<ProductCategoryType>>([])
  //@ts-ignore
  const telegramValues = useMemo(() => window.Telegram.WebApp, [window.Telegram.WebApp])
  const buttonStyle = useMemo(() => ({
    backgroundColor: telegramValues.MainButton.color
  }), [telegramValues])
  useEffect(() => {
    //@ts-ignore
    if (window.Telegram.WebApp) {
      //@ts-ignore
      window.document.body.classList.add(window.Telegram.WebApp.colorScheme)
    }
    //@ts-ignore
  }, [window.Telegram.WebApp])

  //проверяем, осуществляется ли сейчас доставка
  const isDeliveryTime = useMemo(() => {
    if (settings) {
      const currentDay = moment()
      const currentWeekDay = currentDay.format("dddd").toLocaleLowerCase()
      //@ts-ignore
      const deliveryFrom = settings[`${currentWeekDay}_from`]
      //@ts-ignore
      const deliveryTo = settings[`${currentWeekDay}_to`]
      //если нет одного из значений, то считаем, что доставка работает без ограничений
      if (!deliveryFrom || !deliveryTo) {
        return true
      } else {
        //выставляем формат для сравнения
        const timeFormat = "HH:mm:ss"
        //текущее время
        // "2022-07-09 23:50:00"
        const currentDayTime = moment(moment().format(timeFormat), timeFormat)
        //время доставки от в формате moment
        const deliveryFromAsMoment = moment(deliveryFrom, timeFormat)
        //время доставки до в формате moment
        const deliveryToAsMoment = moment(deliveryTo, timeFormat)
        //проверяем, находимся ли мы в спектре работы доставки
        return moment(currentDayTime).isBetween(deliveryFromAsMoment, deliveryToAsMoment)
      }
    } else {
      //если настройки не заданы, то считаем, что доставка работает без ограничений
      return true
    }
  }, [settings])

  const addInCart = useCallback((
    { id, title, image, price, modifiers = [] }: { id: number, title: string, image: string, price: string, modifiers?: Array<any> },
    type: string = "add") => {
    setCart(prev => {
      const arrayClone = [...prev]
      const productInCart = arrayClone.find(product => product.id === id && product.price === price)
      if (productInCart) {
        const productIndex = arrayClone.indexOf(productInCart)
        const productClone = { ...productInCart }
        if (type === "delete") {
          if (productInCart.count === 1) {
            arrayClone.splice(productIndex, 1)
          } else {
            productClone.count = productInCart.count - 1
            arrayClone.splice(productIndex, 1, productClone)
          }
        } else {
          productClone.count = productInCart.count + 1
          arrayClone.splice(productIndex, 1, productClone)
        }
      } else {
        arrayClone.push({ id, title, image, modifiers, price, count: 1 })
      }
      //записываем значение корзины в storage 
      const cartAsString = JSON.stringify(arrayClone)
      localStorage.setItem("cart", cartAsString)
      return arrayClone
    })
  }, [])

  const getProducts = async () => {
    const { data } = await api("products", "get", { page: 1, limit: 2000 })
    setList(data)
  }
 
  const getCategories = useCallback(async () => {
    const { data } = await api("productCategories", "get", { page: 1, limit: 2000 })
    const sortedData = data.sort((a: ProductCategoryType, b: ProductCategoryType) => a.order_position < b.order_position ? -1 : 1)
      .filter((category: ProductCategoryType) => category.products_count && category.is_view_in_site)
    setProductCategories(sortedData)
  }, [])

  const getSettings = useCallback(async () => {
    const { data } = await api("settings", "get")
    setSettings(data)
  }, [])

  // Проверка на сохраненные в storage значения корзины, инициализация категорий, продуктов, настроек 
  useLayoutEffect(() => {
    const cart = localStorage.getItem("cart")
    if (cart) {
      setCart(JSON.parse(cart))
    }
    getCategories()
    getProducts()
    getSettings()
  }, [])

  useEffect(() => {
    (async () => {
      const phone = userPhone ?? localStorage.getItem("client_phone")
      if (phone) {
        const user = await api("clients", "get", { phone })
        if (user?.data.length) {
          //проверяем кол-во бонусов. Если их нет, то будет значение false
          const bonuses = await api("clients", "bonuses-get", { phone })
          setUserBonuses(bonuses.data)
          return setCurrentUser(user.data[0])
        } else {
          await api("clients", "add", { phone })
          const newUser = await api("clients", "get", { phone })
          return setCurrentUser(newUser.data[0])
        }
        
      }
    })()
  }, [userPhone])


  return (
    <div className="container">
      <Context.Provider value={{ buttonStyle, settings, isDeliveryTime, userBonuses }}>
        <Routes>
          <Route
            path="/"
            element={
              <FoodList
                list={list}
                productCategories={productCategories}
                cart={cart}
                addInCart={addInCart}
                setUserPhone={setUserPhone}
              />
            }
          />
          <Route
            path="/payment"
            element={
              <Payment
                cart={cart}
                setCart={setCart}
                addInCart={addInCart}
                currentUser={currentUser}
                setSuccessData={setSuccessData}
              />
            }
          />
          <Route
            path="/success"
            element={
              <Success successData={successData} />
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to="/"
              />
            }
          />
        </Routes>
      </Context.Provider>
    </div>
  )
}

export default App