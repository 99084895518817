import moment from "moment"
import { useMemo } from "react"
import { useContext } from "../context"


const Undelivery = () => {
    const context = useContext()

    const nextDeliveryStartTime = useMemo(() => {
        if (context) {
          
            const currentDay = moment().format("dddd").toLocaleLowerCase()
            const currentDayTimeFormat = moment().format("HH:mm:ss")
            const nextDay = moment().add(1, "days").format("dddd").toLocaleLowerCase()
            //проверяем, зашел ли человек в приложение после полуночи (перед открытием) или до полуночи (после закрытия) через старт доставки на сегодняшний день
            //@ts-ignore
            const isBeforeDeliveryStart = moment(currentDayTimeFormat, "HH:mm:ss").isBefore(moment(context.settings?.[`${currentDay}_from`], "HH:mm:ss"))
            //@ts-ignore
            const nextDeliveryStartTime = isBeforeDeliveryStart ? context.settings?.[`${currentDay}_from`] : context.settings?.[`${nextDay}_from`]
            return nextDeliveryStartTime ? {
                day: isBeforeDeliveryStart ? "today" : "tomorrow",
                time: moment(nextDeliveryStartTime, "HH:mm:ss").format("HH:mm")
            } : null
        } else {
            return null
        }
    }, [context])
    //необходимо именно проверить, false ли, т.к. если выставить через !, то undefined, как и false, приведется к true
    if (context?.isDeliveryTime === false) {
        return <div className="undeliveryTime">{`К сожалению, доставка уже не работает. Ждем Вас ${nextDeliveryStartTime ?
            `${nextDeliveryStartTime.day === "today" ? "сегодня" : "завтра"} с ${nextDeliveryStartTime.time}` : ""}!`}</div>
    }
    return null
} 

export default Undelivery