import React, { useEffect, useMemo } from "react"
import FoodCard from "./FoodCard"
import { FoodCategoryPropsType } from "./types/main"


const FoodCategory: React.FC<FoodCategoryPropsType> = ({ category, list, addInCart, cart, setCurrentCategory }) => {


    const currentProducts = useMemo(() => {
        return list.filter(product => Number(product.category_id.value) === Number(category.id))
    }, [list])

    useEffect(() => {
        if (category.id) {
            const currentElement = document.getElementById(`category-${category.id}`)
            if (currentElement) {
                const checkScroll = () => {
                    if (((window.scrollY + 60) >= currentElement.offsetTop) && (window.scrollY < (currentElement.offsetTop + currentElement.offsetHeight))) {
                        return setCurrentCategory(category.id)
                    }
                }
                window.addEventListener("scroll", checkScroll)
                return () =>   window.removeEventListener("scroll", checkScroll)
            }
        }
    }, [category.id])

    if (!currentProducts.length) {
        return null
    }

    return <li id={`category-${category.id}`} className="product__category">
        <h3 className="product__category-title">{category.title}<span className="product__category-count">{category.products_count}</span></h3>
        <ul className="product__category-container">
            {currentProducts.map(product => <FoodCard
             key={product.id} 
             {...product}
                addInCart={addInCart}
                currentProductInCart={cart.filter(item => item.id === product.id)}
            />)}
        </ul>
    </li>
}

export default FoodCategory